'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

var _request = require('../../common/request');

var _request2 = _interopRequireDefault(_request);

var _config = require('../../common/config');

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderMain = /** @class */function () {
    function HeaderMain() {
        this.headerDom = document.getElementById('kingshiper-header');
        this.pathName = decodeURIComponent(window.location.pathname);
        this.search = decodeURIComponent(window.location.search);
        this.wmRequest = new _request2.default();
        this.init();
    }
    HeaderMain.prototype.init = function () {
        this.textActive();
        this.gotoLogin();
        this.logout();
        this.initChildType();
        this.toggleChildType();
    };
    HeaderMain.prototype.textActive = function () {
        // 一级导航栏文字设置活跃状态
        if (this.pathName.includes('/support')) {
            var activeNav = _tool2.default.$_select(this.headerDom, '.kingshiper-header-center-otherpage a.nav-support');
            activeNav.classList.add('active');
        }
    };
    HeaderMain.prototype.gotoLogin = function () {
        var that = this;
        var hasLoginBtn = document.body.contains(document.getElementsByClassName('kingshiper-header-right-no-login')[0]);
        if (hasLoginBtn) {
            var btn = _tool2.default.$_select(this.headerDom, '.kingshiper-header-right-no-login');
            btn.addEventListener('click', function () {
                if (that.pathName == '/') {
                    window.location.href = '/login';
                } else {
                    window.location.href = "/login?goto=".concat(that.pathName).concat(that.search);
                }
            });
        }
    };
    HeaderMain.prototype.logout = function () {
        var that = this;
        var hasLogOutBtn = document.body.contains(document.getElementsByClassName('kingshiper-header-right-exit')[0]);
        if (hasLogOutBtn) {
            var logOutBtn = _tool2.default.$_select(that.headerDom, '.kingshiper-header-right-exit');
            logOutBtn.addEventListener('click', function () {
                that.wmRequest.ajax({
                    type: 'GET',
                    url: _config2.default.WM_LOGINOUT
                }).then(function (res) {
                    if (res.code == 200) {
                        window.location.reload();
                    }
                });
            });
        }
    };
    HeaderMain.prototype.initChildType = function () {
        var _this = this;
        var tabItems = _tool2.default.$_selectAll(this.headerDom, '.kingshiper-header-center-category-name');
        tabItems.forEach(function (item) {
            item.addEventListener('click', function () {
                var childTypeItem = _tool2.default.$_selectAll(_this.headerDom, '.kingshiper-header-category-left-item');
                childTypeItem.forEach(function (cItem) {
                    return cItem.classList.remove('active');
                });
                var activeGoodsUls = _tool2.default.$_selectAll(_this.headerDom, '.kingshiper-header-category-right ul');
                activeGoodsUls.forEach(function (aItem) {
                    return aItem.classList.remove('active');
                });
                var goodsPanel = item.nextElementSibling;
                if (!item.classList.contains('active')) {
                    tabItems.forEach(function (tItem) {
                        return tItem.classList.remove('active');
                    });
                    item.classList.add('active');
                    var goodsPanels = _tool2.default.$_selectAll(_this.headerDom, '.kingshiper-header-center-category-list');
                    goodsPanels.forEach(function (gItem) {
                        return gItem.classList.remove('show');
                    });
                    goodsPanel.classList.add('show');
                } else {
                    item.classList.remove('active');
                    goodsPanel.classList.remove('show');
                }
                var childTypeFirstItem = _tool2.default.$_selectAll(_this.headerDom, '.kingshiper-header-category-left-item:first-child');
                childTypeFirstItem.forEach(function (cItem) {
                    cItem.classList.add('active');
                    var dataAlias = cItem.getAttribute('data-alias');
                    var activeGoodsUl = _tool2.default.$_select(_this.headerDom, ".kingshiper-header-category-right ul.".concat(dataAlias));
                    activeGoodsUl.classList.add('active');
                });
            });
        });
    };
    HeaderMain.prototype.toggleChildType = function () {
        var _this = this;
        var childTypeItem = _tool2.default.$_selectAll(this.headerDom, '.kingshiper-header-category-left-item');
        childTypeItem.forEach(function (item) {
            item.addEventListener('click', function () {
                childTypeItem.forEach(function (cItem) {
                    return cItem.classList.remove('active');
                });
                item.classList.add('active');
                var activeGoodsUls = _tool2.default.$_selectAll(_this.headerDom, ".kingshiper-header-category-right ul");
                activeGoodsUls.forEach(function (aItem) {
                    return aItem.classList.remove('active');
                });
                var dataAlias = item.getAttribute('data-alias');
                var activeGoodsUl = _tool2.default.$_select(_this.headerDom, ".kingshiper-header-category-right ul.".concat(dataAlias));
                activeGoodsUl.classList.add('active');
            });
        });
    };
    return HeaderMain;
}();
exports.default = HeaderMain;