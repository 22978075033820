'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var WmTool = /** @class */function () {
    function WmTool() {}
    // 邮箱验证
    WmTool.isMail = function (val) {
        var pattern = /^[A-Za-z0-9_-]+([_\.][A-Za-z0-9]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        return pattern.test(val);
    };
    // 时间戳转换
    WmTool.timestampToTime = function (timestamp) {
        var date = new Date(Number(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
    };
    // 生成随机字符串
    WmTool.randomString = function (length) {
        var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) {
            result += str[Math.floor(Math.random() * str.length)];
        }return result;
    };
    //产品列表搜索，根据产品id
    WmTool.dealList = function (arr, id) {
        var list;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].g_id == id) {
                list = arr[i];
                break;
            }
        }
        return list;
    };
    // 标签选择 HTMLSelectElement
    WmTool.$_select = function (mianEle, ele) {
        var res = mianEle.querySelector(ele);
        return res;
    };
    // 标签选择 HTMLSelectElement
    WmTool.$_selectAll = function (mianEle, ele) {
        var res = mianEle.querySelectorAll(ele);
        return res;
    };
    // 标签选择 HTMLInputElement
    WmTool.$_input = function (mianEle, ele) {
        var res = mianEle.querySelector(ele);
        return res;
    };
    //对象按键值排序
    WmTool.objKeySort = function (arys) {
        var newkey = Object.keys(arys).sort();
        var newObj = {};
        for (var i = 0; i < newkey.length; i++) {
            //遍历newkey数组
            newObj[newkey[i]] = arys[newkey[i]];
        }
        return newObj;
    };
    /**
     * 获取url中的参数值
     */
    WmTool.getUrlParam = function (url) {
        try {
            var theRequest = new Object();
            var str = url.split('?')[1].substr(0);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
            }
            return theRequest;
        } catch (e) {
            return false;
        }
    };
    /**
     * 获取cookie
     */
    WmTool.getCookie = function (name) {
        var cookieString = document.cookie;
        var cookies = cookieString.split('; ');
        for (var _i = 0, cookies_1 = cookies; _i < cookies_1.length; _i++) {
            var cookie = cookies_1[_i];
            var _a = cookie.split('='),
                cookieName = _a[0],
                cookieValue = _a[1];
            if (cookieName === name) {
                return cookieValue;
            }
        }
        return null;
    };
    // 密码验证
    WmTool.passwordReg = function (val) {
        var pattern = /^[A-Za-z0-9]{6,12}$/;
        return pattern.test(val);
    };
    return WmTool;
}();
exports.default = WmTool;