'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _headerMain = require('./headerMain');

var _headerMain2 = _interopRequireDefault(_headerMain);

var _secondaryHeaderMain = require('./secondaryHeaderMain');

var _secondaryHeaderMain2 = _interopRequireDefault(_secondaryHeaderMain);

var _footerMain = require('./footerMain');

var _footerMain2 = _interopRequireDefault(_footerMain);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CommonMain = /** @class */function () {
    function CommonMain() {
        new _headerMain2.default();
        new _secondaryHeaderMain2.default();
        new _footerMain2.default();
    }
    return CommonMain;
}();
exports.default = CommonMain;